import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import colors from '../../config/color';
import StoreButton from '../../components/storeButton';

class Homepage extends Component {
  render() {
    return (
      // <Layout showHeader={true} showHero={false}>
      <Layout showHamburgerBtn={false} showAccountBtn={false} showHeader={true}>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        <hr />
        <div className="content">
          <div className="success-content">
            <h1>請選擇平台</h1>
            <div className="store-btn">
              <div className="apple-btn">
                <StoreButton
                  btnStyle={{
                    borderWidth: 2,
                    borderColor: '#6a318e',
                    color: '#6a318e',
                    fontSize: 20,
                  }}
                  text="Apple Store"
                />
              </div>
              <div className="google-btn">
                <StoreButton
                  btnStyle={{
                    borderWidth: 2,
                    borderColor: '#6a318e',
                    color: '#6a318e',
                    fontSize: 20,
                  }}
                  text="Google Play"
                />
              </div>
            </div>
            {/* <div className="text-content">
              <CircleNumber text="1" />
              <div>
                <p>請下載 HereHear 聽聽 App</p>
                <div>
                  <img src="/images/google@3x.png"></img>
                  <img src="/images/apple@3x.png"></img>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <style jsx>{`
          .success-content {
            margin: 20px 16px 50px 16px;
            min-width: 343px;
          }

          hr {
            border: none;
            height: 1px;
            background-color: #e0e0e0;
            min-width: 343px;
            margin: auto;
            margin: 20px 16px 50px 16px;
          }

          h1 {
            color: ${colors['black-p']};
            font-family: NotoSansCJKtc-Medium;
            font-size: 28px;
            line-height: 1.33;
            margin: 32px 0;
            text-align: center;
          }

          .store-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .google-btn {
            margin: 10px 0 0 0;
          }

          @media (min-width: 768px) {
            .content {
              display: flex;
              justify-content: center;
            }

            .success-content {
              margin: 20px 60px 50px 60px;
            }

            hr {
              margin: 20px 0 0 0;
            }

            .store-btn {
              flex-direction: row;
            }

            .google-btn {
              margin: 0 0 0 10px;
            }

        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
